
@import 'src/sass/imports/media-queries'

ngb-modal-window
  overflow: auto

ngb-modal-backdrop
  z-index: 50 !important

.modal-dialog
  min-width: 375px
  padding: 8px

.modal-content
  border-width: 0
  border-radius: 8px
  padding: 32px
