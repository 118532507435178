@charset "UTF-8";
.badge {
  border-radius: 9px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 12px;
}

a {
  text-decoration: none;
  outline: none;
}

button {
  padding: 5px 10px;
  border-radius: 9px;
  outline: none;
  border: none;
}

.cursor {
  cursor: pointer;
}

body {
  min-width: 375px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 16px !important;
}

img {
  max-width: 100%;
}

.table-responsive {
  margin: 16px 0;
}

.table {
  margin: 0;
}

th {
  font-weight: 500;
  cursor: not-allowed;
}
th[sortable] {
  cursor: pointer;
}
th.asc:before {
  content: "↑ ";
}
th.desc:before {
  content: "↓ ";
}

.pagination .page-link {
  color: #000;
}
.pagination .page-item.active .page-link {
  background-color: #000;
  border-color: #000;
}

app-control-field {
  display: block;
  margin-bottom: 16px;
}
app-control-field label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  cursor: pointer;
  transition: color 0.25s;
}
app-control-field input,
app-control-field textarea,
app-control-field select {
  display: block;
  width: 100%;
  height: 37.4px;
  background-color: #FFF;
  font-size: 18px;
  padding: 0 0 8px 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #000;
  border-radius: 0;
  outline: none;
  transition: color 0.5s, border-color 0.5s;
}
app-control-field input:focus,
app-control-field textarea:focus,
app-control-field select:focus {
  border-color: #000;
}
app-control-field textarea {
  min-height: 190px;
  max-height: 190px;
}
app-control-field app-control-file {
  line-height: 1;
  padding: 0 0 8px 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  cursor: pointer;
}
app-control-field app-control-file button {
  background-color: #000;
  color: #FFF;
  padding: 4px 8px;
  border-width: 0;
  border-radius: 4px;
  transition: background-color 0.5s, color 0.5s;
}
app-control-field app-control-file button:disabled {
  background-color: #F2F3F3;
  color: #798292;
  cursor: not-allowed;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #000;
  border-color: #000;
}

.custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.custom-control-label {
  cursor: pointer;
}

a {
  cursor: pointer;
}

input[type=button]:disabled, input[type=submit]:disabled, button:disabled {
  cursor: not-allowed;
}

.view-label {
  display: block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.view-label + img {
  margin-top: 7.5px;
}

ngb-modal-window {
  overflow: auto;
}

ngb-modal-backdrop {
  z-index: 50 !important;
}

.modal-dialog {
  min-width: 375px;
  padding: 8px;
}

.modal-content {
  border-width: 0;
  border-radius: 8px;
  padding: 32px;
}